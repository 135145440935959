<template>
	<div id="storeDetail" class="el-content">
		<a-tabs v-model:activeKey="active" @change="changeTab">
		    <a-tab-pane key="1" tab="店铺信息">
				<div class="sd-info">
					<div class="sd-title">
						<span>店铺信息</span>
						<a-space>
							<a-button type="danger" @click="putAwayGoods('good')">下架商品</a-button>
							<a-button type="danger" @click="putAwayGoods('land')">下架土地</a-button>
							<a-button type="danger" @click="putAwayGoods('adopt')">下架认养</a-button>
							<a-button type="danger" @click="putAwayGoods('live')">下架监控</a-button>
							<a-button type="primary" @click="update = 1">修改店铺信息</a-button>
						</a-space>
					</div>
					<a-form ref="form1" :model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
						<div class="sd-info-contanier">
							<a-avatar :src="form.logo" :size="64"></a-avatar>
							<div class="sdic-item">
								<a-form-item label="店铺名称">
									<a-input v-model:value="form.name" v-if="update==1"></a-input>
									<span v-else>{{form.name}}</span>
								</a-form-item>
								<a-form-item label="联系电话">
									<a-input v-model:value="form.mobile" v-if="update==1"></a-input>
									<span v-else>{{form.mobile}}</span>
								</a-form-item>
								<a-form-item label="店铺等级" v-if="update == 2">
									<div class="sdic-shop-level" v-if="form.shop_level"> 
										<img :src="form.shop_level.level_logo" alt="">
										{{form.shop_level.level_name}}
									</div>
									<span v-else>暂无等级</span>
								</a-form-item>
								<a-form-item label="店铺评分" v-if="update == 2">
									<div class="sdic-shop-other">
										<a-rate v-model:value="form.shop_score" />
									</div>
								</a-form-item>
								<a-form-item label="店铺认证" v-if="update == 2">
									<div class="sdic-shop-other" v-if="form.certs">
										<a-tag color="#00CC66" v-for="(v,i) in form.certs" :key="i">
											<div style="display: flex;align-items: center;">
												<img :src="v.cert_logo" alt="" style="width: 15px;height: 15px;">
												{{v.cert_name}}
											</div>
										</a-tag>
									</div>
									<span v-else>暂无认证</span>
								</a-form-item>
								<a-form-item label="店铺余额"  v-if="update==2" >
									<span style="color: rgb(255, 21, 69);font-weight: bold;" v-if="money">￥{{money.bank}}</span>
									<router-link v-if="money" :to="{path:'/account/moneyRecord',query:{id:money.id,type:1}}">
										<a-button type="link">交易明细</a-button>
									</router-link>
									<router-link v-if="money" :to="{path:'/account/moneyRecord',query:{id:money.id,type:2}}">
										<a-button type="link">资金明细</a-button>
									</router-link>
								</a-form-item>
								<a-form-item label="店铺保证金" v-if="update == 2">
									<span v-if="form.bail">{{form.bail.amount}}元</span>
									<a-button v-if="form.bail && form.bail.amount>0" type="link" @click="refundBail">退还保证金</a-button>
								</a-form-item>
								
								<a-form-item label="店铺LOGO" v-if="update==1" >
									<kd-img-select :src="form.logo" @success="(url)=>{ form.logo = url }"></kd-img-select>
								</a-form-item>
								
								<a-form-item label="交易手续费">
									<a-input v-model:value="form.handlingFee" v-if="update==1" addon-after="%">
									</a-input>
									<span v-else>{{form.handlingFee}}%</span>
								</a-form-item>
								<a-form-item v-if="update == 1" :wrapper-col="{ offset: 4 }">
									<a-space>
										<a-button type="primary" @click="submitSave">保存</a-button>
										<a-button @click="update=2">取消</a-button>
									</a-space>
								</a-form-item>
							</div>
							
							<div class="sdic-item">
								<a-form-item label="是否展示手机号" v-if="update != 2">
									<a-radio-group v-model:value="form.showmobile">
									    <a-radio :value="1">展示</a-radio>
									    <a-radio :value="2">不展示</a-radio>
									</a-radio-group>
								</a-form-item>
								<a-form-item label="店铺状态">
									<a-tag v-if="update == 2" :color="form.lib_status == 1 ?'#00CC66':'#FF0066'">{{form.lib_status == 1 ?'启用':'禁用'}}</a-tag>
									<a-radio-group v-model:value="form.lib_status" v-else>
									    <a-radio :value="1">启用</a-radio>
									    <a-radio :value="2">禁用</a-radio>
									</a-radio-group>
								</a-form-item>
								
								<a-form-item label="封禁状态">
									<a-tag :color="form.forbid_time ?'#FF0066':'#00CC66'">{{form.forbid_time ?'封禁到期时间：'+form.forbid_time:'正常使用'}}</a-tag>
									<a-button type="link" @click="show.forbid= true">编辑</a-button>
								</a-form-item>
								
								<a-form-item label="图片展示">
									<kd-img-select v-if="update==1" :mutil="true" :src="form.images" @success="(url)=>{ form.images = url}"></kd-img-select>
									<a-image v-else style="width: 60px; height: 40px" :src="form.images[0]"/>
								</a-form-item>
								<a-form-item label="店铺简介">
									<a-input v-if="update==1" type="textarea" v-model:value="form.description"></a-input>
									<span v-else>{{form.description}}</span>
								</a-form-item>
							</div>
							
							<div class="sdic-item">
								<a-form-item label="店铺地址">
									<span v-if="update!=1">{{form.address}}</span>
									<div v-if="update==1">
										<kd-map :value="form.longitude+','+form.latitude" @change="getAddress"></kd-map>
									</div>
								</a-form-item>
								<a-form-item label="店铺标签">
									<a-tag :key="tag"  v-for="tag in form.tag"
									  closable
									  :disable-transitions="false"
									  @close="handleClose(tag)">
									  {{tag}}
									</a-tag>
									<template v-if="update==1">
										<a-input
											v-if="inputVisible"
											ref="inputRef"
											type="text"
											size="small"
											:style="{ width: '78px' }"
											v-model:value="inputValue"
											@blur="handleInputConfirm"
											@keyup.enter="handleInputConfirm"
										/>
										<!-- <a-input
										  class="input-new-tag"
										  v-if="inputVisible"
										  v-model:value="inputValue"
										  ref="saveTagInput"
										  size="small"
										  @keyup.enter.native="handleInputConfirm"
										  @blur="handleInputConfirm" >
										</a-input> -->
										<a-button v-else class="button-new-tag" size="small" @click="showInput">+ 新增标签</a-button>
									</template>
								</a-form-item>
								<a-form-item label="备注">
									<a-input v-if="update==1" type="textarea" v-model="form.note"></a-input>
									<span v-else>{{form.note || '无'}}</span>
								</a-form-item>
							</div>
						</div>
					</a-form>
				
					<div class="sd-title">账户信息</div>
					<a-form ref="form2" v-model="form" :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }">
						<div class="sd-info-contanier">
							<a-avatar :src="form.logo" :size="64"></a-avatar>
							<div class="sdic-item">
								<a-form-item label="用户昵称">
									<span>{{form.user.name}}</span>
								</a-form-item>
								<a-form-item label="绑定电话">
									<span>{{form.user.mobile}}</span>
								</a-form-item>
								<a-form-item label="最后登录ip">
									<span>{{form.user.last_login_ip}}</span>
								</a-form-item>
								<a-form-item label="最后登录时间">
									<span>{{form.user.create_time}}</span>
								</a-form-item>
							</div>
						</div>
					</a-form>
				</div>
			</a-tab-pane>
		    <a-tab-pane key="3" tab="商品" force-render>
				<a-input-search style="width: 400px;"
					v-model:value="search.key" 
					placeholder="商品名称" 
					enter-button 
					@search="getGoodsList(1,good.limit)" />
					
				<a-table :pagination="false" row-key="id" :data-source="good.list" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'商品',dataIndex:'goods_name',slots:{customRender:'goods_name'}},
					{title:'价格',dataIndex:'price'},
					{title:'库存',dataIndex:'count'},
					{title:'上架',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
					{title:'推荐',dataIndex:'is_recommend',slots:{customRender:'is_recommend'}},
					{title:'审核状态',dataIndex:'audit',slots:{customRender:'audit'}},
					{title:'排序',dataIndex:'rank'},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]" >
					<template #goods_name="{record}">
						<div class="sdg-name">
							<div style="width: 70px;height: 70px;margin-right: 10px;">
								<a-image :src="record.goods_slide[0]"/>
							</div>
							<div class="kd-goodsList-name">
								<div>{{record.goods_name}}</div>
							</div>
						</div>
					</template>
					<template #is_put_away="{record}">
						<a-tag color="#00CC66"  v-if="record.is_put_away==1">已上架</a-tag>
						<a-tag color="#999" v-if="record.is_put_away==0" >已下架</a-tag>
					</template>
					<template #is_recommend="{record}">
						<a-tag color="#00CC66" v-if="record.is_recommend==1" >推荐</a-tag>
						<a-tag color="#999" v-if="record.is_recommend==0" >不推荐</a-tag>
					</template>
					<template #audit="{record}">
						<a-tag
							:type="record.audit==0 ?'#999':'#00CC66'">{{record.audit == 0?'待审核':'审核通过'}}
						</a-tag>
					</template>
					<template #action="{record}">
						<a-button v-has="{action:'/goods/goodsEdit'}" type="link" @click="toEditGoods(record.id,record.shop_id)">详情</a-button>
					</template>
				</a-table>
				<div class="pager">
					<a-pagination
						show-size-changer
						:default-current="land.page"
						:total="land.count"
						@showSizeChange="(p,e)=>{getGoodsList(land.page,e)}"
						@change="(e)=>{getGoodsList(e,land.limit)}"
					/>
				</div>
			</a-tab-pane>
		    <a-tab-pane key="4" tab="土地">
				<a-input-search style="width: 400px;"
					v-model:value="search.key" 
					placeholder="商品名称" 
					enter-button 
					@search="getLandList(1,land.limit)" />
				<a-table :pagination="false" row-key="id" :data-source="land.list" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'名称',dataIndex:'goods_name',slots:{customRender:'goods_name'}},
					{title:'总面积(㎡)',dataIndex:'all_area'},
					{title:'空闲',dataIndex:'wait_count'},
					{title:'上架',dataIndex:'is_put_away',slots:{customRender:'is_put_away'}},
					{title:'排序',dataIndex:'rank'},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]" >
					<template #goods_name="{record}">
						<div class="kd-adoptlist">
							<a-image style="width: 70px;height: 70px;margin-right: 10px;" class="kd-adoptlist-cover" :src="record.cover" />
							<span>{{record.land_name}}</span>
						</div>
					</template>
					<template #is_put_away="{record}">
						<a-tag color="#00CC66"  v-if="record.is_put_away==1">已上架</a-tag>
						<a-tag color="#999" v-if="record.is_put_away==0" >已下架</a-tag>
					</template>
					<template #action="{record}">
						<a-button v-has="{action:'/goods/goodsEdit'}" type="link" @click="toEditGoods(record.id,record.shop_id)">详情</a-button>
					</template>
				</a-table>	
				<div class="pager">
					<a-pagination
						show-size-changer
						:default-current="land.page"
						:total="land.count"
						@showSizeChange="(p,e)=>{getGoodsList(land.page,e)}"
						@change="(e)=>{getGoodsList(e,land.limit)}"
					/>
				</div>
			</a-tab-pane>
			<a-tab-pane key="5" tab="认养">
				<a-input-search style="width: 400px;"
					v-model:value="search.key" 
					placeholder="认养名称" 
					enter-button 
					@search="getAdoptList(1,adopt.limit)" />
				<a-table :pagination="false" row-key="id" :data-source="adopt.list" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'名称',dataIndex:'goods_name',slots:{customRender:'goods_name'}},
					{title:'价格',dataIndex:'price'},
					{title:'销量',dataIndex:'buy_count'},
					{title:'排序',dataIndex:'rank'},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]" >
					<template #goods_name="{record}">
						<div class="kd-adoptlist">
							<a-image style="width: 70px;height: 70px;margin-right: 10px;" class="kd-adoptlist-cover" :src="record.cover"/>
							<span>{{record.name}}</span>
						</div>
					</template>
					<template #is_put_away="{record}">
						<a-tag color="#00CC66"  v-if="record.is_put_away==1">已上架</a-tag>
						<a-tag color="#999" v-if="record.is_put_away==0" >已下架</a-tag>
					</template>
					<template #action="{record}">
						<a-button v-has="{action:'/adopt/edit'}" type="link" @click="toEditGoods(record.id,record.shop_id)">详情</a-button>
					</template>
				</a-table>	
				<div class="pager">
					<a-pagination
						show-size-changer
						:default-current="adopt.page"
						:total="adopt.count"
						@showSizeChange="(p,e)=>{getAdoptList(adopt.page,e)}"
						@change="(e)=>{getAdoptList(e,adopt.limit)}"
					/>
				</div>
			</a-tab-pane>
		</a-tabs>
		
		<a-modal v-model:visible="show.balance" title="余额记录" :footer="null" width="600px" @cancel="show.balance = false">
			<a-table :pagination="false" row-key="id" :data-source="balanceRecord" :columns="[
				{title:'描述',dataIndex:'description'},
				{title:'操作说明',dataIndex:'operation_type_show'},
				{title:'操作金额',dataIndex:'coupon_count',slots:{customRender:'coupon_count'}},
				{title:'操作后金额',dataIndex:'after'},
				{title:'操作时间',dataIndex:'create_time'},
				{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
			]" >
				<template #coupon_count="{record}">
					<p class="money-success" v-if="record.type==1">+￥{{record.change}}</p>
					<p class="money-danger" v-else>-￥{{record.change}}</p>
				</template>
				<template #action="{record}">
					<a-tooltip v-if="record.is_del==1" title="点击撤回操作后,此条操作记录的操作金额会将重新进入用户可用余额中!">
						<a-button v-if="record.is_del==1" type="danger" size="small" @click="recallMoney(record.id)">撤回操作</a-button>
					</a-tooltip>
				</template>
			</a-table>
			<div class="pager">
				<a-pagination
					show-size-changer
					:default-current="balanceRecord.page"
					:total="balanceRecord.count"
					@showSizeChange="(p,e)=>{getMoneyRecord(balanceRecord.page,e)}"
					@change="(e)=>{getMoneyRecord(e,balanceRecord.limit)}"
				/>
			</div>
		</a-modal>
		<a-modal v-model:visible="show.forbid" title="商户封禁/解封设置" @ok="saveSubmit" width="300px" @cancel="show.forbid = false">
			<a-date-picker v-model:value="forbidForm.end_at" show-time />
		</a-modal>
		
	</div>
</template>

<script>
import KdMap from '@/components/public/kd-map.vue'
import { nextTick, reactive, toRefs,ref } from 'vue'
import { useRoute,onBeforeRouteLeave } from 'vue-router'
import shopModel from '@/api/addons/shop'
import goodsModel from '@/api/goods'
import adoptModel from '@/api/adopt'
import landModel from '@/api/land'
import router from '@/router'
import store from '@/store'

export default{
	components:{
		KdMap
	},
	setup(){
		const inputRef = ref();
		const _d = reactive({
			active:'1',
			shop_id:0,
			update:2,
			form:{
				name:'',
				account:{nickname:'',openid:''},
				logo:'',
				card_img:'',
				business_img:'',
				lib_status:1,
				note:'',
				latitude:'',
				longitude:'',
				user:{name:'',mobile:'',last_login_ip:'',create_time:''},
				tag:[],
				images:[],
				handlingFee:'',
				showmobile:1,
			},
			inputVisible:false,
			inputValue:'',
			show:{
				balance:false,	//余额记录
				forbid:false,	//解封设置
			},
			good:{
				page:1,
				list:[],
				limit:10,
				count:0,
			},
			land:{
				page:1,
				list:[],
				limit:10,
				count:0,
			},
			adopt:{
				page:1,
				list:[],
				limit:10,
				count:0,
			},
			balanceRecord:{
				page:1,
				list:[],
				limit:10,
				count:0,
			},
			forbidForm:{
				end_at:'',
			},
			search:{
				goods_name:'',
				land_name:'',
				adopt_name:'',
			},
			money:null,
		})
		const opt = useRoute().query
		getShopDetail()
		shopModel.getShopMoney(opt.id,res=>_d.money =res)

		function getShopDetail(){
			shopModel.getShopDetail(opt.id,res=>{
				_d.form = res
				if( !res.tag ) _d.form.tag = []
				if( !res.images ) _d.form.images = []
			})

			getMoneyRecord(_d.balanceRecord.page,_d.balanceRecord.limit)
		}

		function getMoneyRecord(page,limit){
			shopModel.getShopBankLog(page,limit,{user_id:opt.id,type:2},res=>_d.balanceRecord = {limit,...res})
		}

		function submitSave(){
			shopModel.addOrEditShop(_d.form)
		}
		function showInput(){
			_d.inputVisible = true;
			nextTick(() => {
				inputRef.value.focus();
			});
		}
		
		function handleInputConfirm(){
			let inputValue = _d.inputValue
			if (inputValue) {
				_d.form.tag.push(inputValue)
			}
			_d.inputVisible = false;
			_d.inputValue = '';
		}

		function changeTab(){
			let { active } = _d
			if( active == '3'){
				getGoodsList(1,_d.good.limit)
			}
			if( active == '5'){
				getAdoptList(1,_d.adopt.limit)
			}
			if( active == '4'){
				getLandList(1,_d.land.limit)
			}
		}

		function getGoodsList(page,limit){
			goodsModel.getGoodsList(page,limit,{shop_id:opt.id,..._d.search},res=>_d.good = {limit,...res})
		}

		function getAdoptList(page,limit){
			adoptModel.getAdoptList(page,limit,{shop_id:opt.id,..._d.search},res=>_d.adopt = {limit,...res})
		}
		function getLandList(page,limit){
			landModel.getLandList(page,limit,{shop_id:opt.id,..._d.search},res=>_d.land = {limit,...res})
		}

		//退还保证金
		const refundBail = ()=>shopModel.refundShopBond(opt.id,_d.form.bail.amount,()=>getShopDetail())
		const saveSubmit = ()=>shopModel.setShopForbid(opt.id,_d.forbidForm.end_at,()=>{
			_d.show.forbid = false
			getShopDetail()
		})

		const putAwayGoods = (model)=>shopModel.changeShopGoodsStatus(opt.id,model,2)

		function getAddress(e){
			_d.form.latitude = e.latitude
			_d.form.longitude = e.longitude
			_d.form.address = e.addresses
		}

		function toEditGoods(id,shop_id=0){
			let { active } = this ,path = '/goods/goodsEdit'
			if( active == 5) path ='/adopt/edit'
			if( active == 4) path ='/land/landEdit'
			if( shop_id ){
				router.push({path:path,query:{id,shop_id} })	
			}else{
				router.push({path:path,query:{id} })
			}
		}

		const handleClose = tag=>_d.form.tag.splice(_d.form.tag.indexOf(tag),1)

		onBeforeRouteLeave((to,from,next)=>{
			if( to.path == '/adhibition/store/list'){
				store.dispatch("setKeepPage",{name:to.name,type:1})
			}else{
				store.dispatch("setKeepPage",{name:to.name,type:2})
			}
			next()
		})

		return{
			...toRefs(_d),
			getMoneyRecord,
			getShopDetail,
			submitSave,
			handleClose,
			handleInputConfirm,
			getGoodsList,
			getAdoptList,
			getLandList,
			saveSubmit,
			refundBail,
			getAddress,
			putAwayGoods,
			showInput,
			changeTab,
			inputRef,
			toEditGoods
		}
	},
}
</script>

<style lang="scss"  scoped>
	.sd-title{
		width: 100%;
		display: flex;
		align-items: center;
		height: 48px;
		justify-content: space-between;
		background: #f6f6f6;
		padding: 0 24px;
		>span{
			font-weight: bold;
			font-size: 16px;
		}
	}
	.sd-info{
		&-contanier{
			width: 100%;
			display: flex;
			margin-top: 20px;
			.el-form-item{
				margin-bottom: 10px;
			}
			
			.sdic-item{
				width: 30%;
				
				.sdic-shop-level{
					display: flex;
					align-items: center;
					color: #ff9200;
					img{
						width: 15px;
						height: 15px;
						margin-right: 10px;
					}
				}
			}
			
			.sdic-shop-other{
				margin-top: 4px;
				cursor: pointer;
			}
		}
		
		.map-input{
			display: flex;
			width: 100%;
			margin-top: 10px;
		}
	}
	
	.money-success{
		color: #00bd5f;
		font-weight: bold;
	}
	
	.money-danger{
		color: #f70000;
		font-weight: bold;
	}    
	.sdg-name{
		display: flex;
	}
</style>
